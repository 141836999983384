<template>
  <div>
    <div class="commonInputTopBox">
      <pageHeader :pagename="'所有设备'" :total="total"/>
      <CommonHeaders
        :formConfig="formConfig"
        :formModel="formModel"
        :buttons="buttons"
        @button-click="handleButtonClick"
      ></CommonHeaders>
    </div>

    <div class="commonControl-body">
      <!-- <el-button type="primary" @click="addFormButton()">新增设备</el-button> -->
      <common-table
        :tableData="tableData"
        :tableButton="tableButton"
        :tableLabel="tableLabel"
        @edit="editFirstLevel"
      ></common-table>
      <div class="layoutBorder">
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="currentPage"
          :page-sizes="[5, 10, 20, 40]"
          :page-size="pagesize"
          layout="total, sizes, prev, pager, next, jumper"
        >
        </el-pagination>
      </div>
    </div>
    <!-- 点击事件触发 -->
    <el-dialog title="编辑设备" :visible.sync="dialogFormVisibleEdit">
      <el-form :model="formEdit">
        <el-form-item label="设备id" label-width="120px">
          <el-input v-model="formEdit.id" autocomplete="off" :disabled="true"></el-input>
        </el-form-item>
        <el-form-item label="设备名" label-width="120px">
          <el-input v-model="formEdit.equipment_name" autocomplete="off"></el-input>
        </el-form-item>
        <!-- <el-form-item label="注释" label-width="120px"> 
            <el-input v-model="formEdit.annotation" autocomplete="off"></el-input>
        </el-form-item> -->

        <el-form-item label="设备型号" label-width="120px">
          <el-input v-model="formEdit.model_number" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="设备类型" label-width="120px">
          <el-select v-model="formEdit.type_id" placeholder="请选择" filterable>
            <el-option
              v-for="item in value"
              :key="item.id"
              :label="item.equipment_type_name"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="设备品牌" label-width="120px" >
          <el-select v-model="formEdit.brand_id" placeholder="请选择" filterable>
            <el-option
              v-for="item in value2"
              :key="item.id"
              :label="item.brand_name"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisibleEdit = false">取 消</el-button>
        <el-button type="primary" @click="updateFirstLevel()">确 定</el-button>
      </div>
    </el-dialog>
    <el-dialog title="新增设备" :visible.sync="dialogFormVisibleAdd">
      <el-form :model="formAdd">
        <el-form-item label="设备名" label-width="120px">
          <el-input v-model="formAdd.equipment_name" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="注释" label-width="120px">
          <el-input v-model="formAdd.annotation" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="型号" label-width="120px">
          <el-input v-model="formAdd.model_number" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="设备类型" label-width="120px">
          <el-select v-model="formAdd.type_id" placeholder="请选择" filterable>
            <el-option
              v-for="item in value"
              :key="item.id"
              :label="item.equipment_type_name"
              :value="item.type_id"
            >
            </el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="品牌" label-width="120px">
          <el-select v-model="formAdd.brand_id" placeholder="请选择" filterable>
            <el-option
              v-for="item in value2"
              :key="item.id"
              :label="item.brand_name"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisibleAdd = false">取 消</el-button>
        <el-button type="primary" @click="createFirstLevel()">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
// import {
//   product_get,
//   product_classify_create,
//   product_set,
//   product_edit,
//   product_classify_get
// } from "@/api/product.js";
import {
  equipment_get,
  equipment_type_get,
  equipment_brand_get,
  equipment_create,
  equipment_update,
  equipment_delete
} from "@/api/manage/operation/equipment/equipment.js";
import CommonTable from "frontelementpackage/src/CommonTableButtonFixed.vue";
import CommonHeaders from "@/components/shibo_Common/CommonHeaders.vue";

export default {
  name: "start",
  data() {
    return {
      query: {
        querypage: 1, //获取页码
        pagesize: 10, //每页返回数量
        keyword: "", //搜索 可搜邮箱 用户名 手机号
      },
      //分页数据
      total: 0,
      currentPage: 1, //初始页
      pagesize: 10, //    每页的数据
      tableData: [],
      dialogFormVisibleEdit: false,
      formEdit: { group_info: "" },
      dialogFormVisibleAdd: false,
      formAdd: {},
      tableLabel: [
        {
          prop: "id",
          label: "id",
          // width: 100,
          minWidth:"10%"
          // position:"right"
        },
        {
          prop: "equipment_name",
          label: "设备名",
          // width: 220,
          minWidth:"20%"
        },
        {
          prop: "model_number",
          label: "型号",
          // width: 220,
          minWidth:"20%"
        },
        {
          prop: "equipment_type",
          label: "设备分类",
          // width: 220,
          minWidth:"20%"
        },
        {
          prop: "model_brand",
          label: "设备品牌",
          width: 200,
        },
      ],
      tableButton: {
        width: 250,
        data: [
          {
            name: "查看详情",
            type: "info",
            size: "small",
          }
        ],
      },
      value: null,
      optionValue: null,
      value2: null,
      optionValue2: null,
      //过滤器参数
      formConfig: [
        {
          label: '搜索产品',
          prop: 'keyword',
          component: 'el-input',
          placeholder: '请输入关键词'
        }
      ],
      formModel: {},
      buttons: [
        { label: "查询", type: "primary",action:'search' },
        { label: "新增设备", type: "primary",action:'create' },
      ]
    };
  },
  components: {
    CommonTable,
    CommonHeaders
  },
  created() {
    this.getFirstLevel();
    this.initOption();
  },
  methods: {
    initOption() {
      equipment_type_get(this.query).then((res) => {
        if (res.code == 200) {
          this.value = res.data;
        } else {
          this.$message.info(res.msg);
        }
      });
      equipment_brand_get(this.query).then((res) => {
        if (res.code == 200) {
          this.value2 = res.data;
        } else {
          this.$message.info(res.msg);
        }
      });
    },
    editFirstLevel(row, methods) {
      if (methods == "查看详情") {
        this.gotoUser(row);
      }
    },
    gotoUser(row) {
      this.$router.push({ name: "equipment_info", query:{
        equipment_id: row.id,
        equipment_name: row.equipment_name,
        equipment_type_name: row.equipment_type.equipment_type_name,
        brand_name: row.model_brand.brand_name,
        model_number: row.model_number
      }  });
    },
    editUser(row) {
      this.formEdit = row;
      // console.log(this.form,"ddd")
      this.dialogFormVisibleEdit = true;
      // row;
      // this.$router.push({ name: "productLibraryInfo", query: { id: row.id } });
    },
    delUser(row) {
      let ids = row.id;
      console.log({ group_id: ids });
      this.$confirm("是否确定删除", "确认信息", {
        distinguishCancelAndClose: true,
        confirmButtonText: "删除",
        cancelButtonText: "放弃删除",
      })
        .then(() => {
          equipment_delete({
            "equipment_id": ids, //产品ID
            
          }).then((res) => {
            if (res.code == 200) {
              this.$message.success("成功删除");
              this.getFirstLevel()
            } else {
              this.$message.info(res.msg);
            }
          });
        })
        .catch((action) => {
          this.$message({
            type: "info",
            message: action === "cancel" ? "放弃删除" : "停留在当前",
          });
        });
    },
    handleCurrentChange(val) {
      this.currentPage = val;
      this.query = {
        querypage: this.currentPage,
        pagesize: this.pagesize,
        keyword: "",
      };
      //  console.log("当前页面显示xxx条", this.currentPage,"当前界面是xxx页",this.currentPage);
      equipment_get(this.query).then((res) => {
        this.total = res.data.total;
        this.tableData = res.data.result;
      });
    },
    handleSizeChange(val) {
      this.pagesize = val; //获取page-sizes里的每页显示几条数据的值，赋给我们自定义的每页显示数量的变量pageNum
      // console.log("当前页面显示xxx条", val,"当前界面是xxx页",this.currentPage);
      this.query = {
        querypage: this.currentPage,
        pagesize: this.pagesize,
        keyword: "",
      };
      console.log(this.query);
      equipment_get(this.query).then((res) => {
        this.total = res.data.total;
        this.tableData = res.data.result;
      });
    },

    createFirstLevel() {
      equipment_create(this.formAdd).then((res) => {
        if (res.code == 200) {
          this.$message.success("成功添加");
          window.location.reload();
        } else {
          this.$message.info(res.msg);
        }
      });
      console.log(this.formAdd, "添加的数据");
      this.dialogFormVisibleAdd = false;
    },
    addFormButton() {
      this.dialogFormVisibleAdd = true;
    },
    updateFirstLevel() {
      Reflect.deleteProperty(this.formEdit, "equipment_type");
      Reflect.deleteProperty(this.formEdit, "model_brand");
      Reflect.deleteProperty(this.formEdit, "product_type");
      this.formEdit["annotation"] =""
      console.log(this.formEdit, "编辑的数据");
      this.formEdit["equipment_id"] = this.formEdit["id"];
      // Reflect.deleteProperty(this.formEdit,"id")
      Reflect.deleteProperty(this.formEdit, "id");
      console.log(this.formEdit);
      equipment_update(this.formEdit).then((res) => {
        if (res.code == 200) {
          this.$message.success("成功修改");
          this.getFirstLevel();
        } else {
          this.$message.info(res.msg);
        }
      });
      this.dialogFormVisibleEdit = false;
    },
    getFirstLevel() {
      equipment_get(this.query).then((res) => {
        this.total = res.data.total;
        this.tableData = res.data.result;
      });
    },
    handleFormSubmit(formData){
      this.query.keyword = formData.keyword;
      equipment_get(this.query).then((res) => {
        this.total = res.data.total;
        this.tableData = res.data.result;
      });
    },
    handleButtonClick(button,formData){
      if (button.action === "search") {
        this.handleFormSubmit(formData);
      } else if (button.action === "create") {
        this.addFormButton();
      }
    }
  },
};
</script>

<style lang="scss" scoped></style>
